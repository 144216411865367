









import Vue from "vue";
import NavSideBar from "@/components/candidate/NavSideBar.vue";

export default Vue.extend({
  name: "InstituteWrapper",
  components: { NavSideBar }
});
